import React, { useState } from "react";
import Button from "../common/button";
import Notes from "../../forms/notes";

const App = ({ data, brand }) => {
	const [collapse, toggleCollapse] = useState(data.expandComment);
	const [constrain, toggleConstrain] = useState(true);

	const dynatraceLink =
		process.env.REACT_APP_DYNATRACE_LINK +
		data.performance_id +
		"?gtf=-365d&gf=all";

	const task = data.accomplish
		? data.accomplish === "yes"
			? "success"
			: "failed"
		: "N/A";

	const taskColor = data.accomplish
		? data.accomplish === "yes"
			? "positive"
			: "negative"
		: "";

	const setColor = (data) => {
		let rate = "positive";
		data < 7 ? (rate = "negative") : data < 8 && (rate = "neutral");
		return rate;
	};

	return (
		<div className={"card app " + task} key={data.id}>
			<div className='header'>
				<div className='row'>
					<div className={"col" + (constrain ? " ellipsis" : "")}>
						<span
							className='ellipsis-click'
							title={data.reason}
							onClick={() => toggleConstrain(!constrain)}
						>
							{data.reason}
						</span>{" "}
						- <span className={taskColor}>{task}</span>
					</div>

					{typeof data.rating !== undefined && typeof data.nps !== undefined ? (
						<div className='col text-center'>
							CSAT <span className={setColor(data.rating)}>{data.rating}</span>{" "}
							NPS <span className={setColor(data.nps)}>{data.nps}</span>
						</div>
					) : data.rating ? (
						<div className='col text-center'>
							CSAT <span className={setColor(data.rating)}>{data.rating}</span>
						</div>
					) : (
						data.nps && (
							<div className='col text-center'>
								NPS <span className={setColor(data.nps)}>{data.nps}</span>
							</div>
						)
					)}
					<div className='col text-right'>{data.date}</div>
				</div>
			</div>
			<div className='comment'>
				{data.comments}
				<div className='notes'>
					<Notes link={data.link} id={data.id} existingNote={data.notes} />
				</div>
			</div>
			{collapse && (
				<div className='details collapse show'>
					<hr />
					<div className='row'>
						{!data.performance_id || data.performance_id === "NA" || data.performance_id === null ? (
							<div className='col'>Dynatrace ID : N/A </div>
						) : (
							<div className='col ellipsis'>
								Dynatrace ID :{" "}
								<a
									href={dynatraceLink}
									title='Dynatrace session link'
									target='_blank'
									rel='noreffer'
								>
									View session
								</a>
							</div>
						)}

						<div className='col'>Launched: {data.survey_trigger}</div>
					</div>
					<div className='row'>
						<div className='col line-compress'>
							Analytics ID :{" "}
							<span title={data.analytics_id}>{data.analytics_id}</span>
						</div>
						<div className='col'>CA# : {data.ca_number}</div>
					</div>
					<div className='row'>
						<div className='col'>Brand : {data.brand}</div>
						<div className='col'>OS : {data.mobile_os}</div>
					</div>
					<hr />
					<div className='row'>
						<div className='col'>Device : {data.device}</div>
						<div className='col'>Device details : {data.device_details}</div>
					</div>
					<hr />
					<div className='row'>
						<div className='col'>Survey Trigger : {data.survey_trigger}</div>
						<div className='col'> Language : {data.language}</div>
					</div>
					<div className='row'>
						<div className='col-6'>User Email : {data.user_email}</div>
						<div className='col-6'>User Phone : {data.user_phone}</div>
						<div className='col-6'>
							User Followup : {data.user_followup ? "Yes" : "No"}
						</div>
					</div>
				</div>
			)}
			<div className='footer'>
				<div className='alert-status'>{data.alertStatus}</div>
				<Button
					className='expand-btn'
					onClick={() => toggleCollapse(!collapse)}
				>
					{collapse ? String.fromCharCode(10005) : String.fromCharCode(10530)}
				</Button>
				<div className='mt-2 muted'>ID: {data.id}</div>
			</div>
		</div>
	);
};

export default App;
