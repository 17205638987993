import React, { useState, useEffect, useCallback, useContext } from "react";
import { updateNote, emailNote, deleteNote, postNote } from "../services/post";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import session from "../services/session";
// Components
import Input from "./inputs/input";
import Textarea from "./inputs/textarea";
import Button from "../components/common/button";
import Loading from "../components/common/loading";
import { UserContext } from "../context/user";

const Notes = ({ link, id, existingNote = [{}] }) => {
	const [loading, setLoading] = useState(false);
	const [existingNoteValues, updateExistingNoteValues] = useState([]);
	const [showNote, toggleShowNote] = useState(false);
	const [showEmail, toggleShowEmail] = useState(false);
	const [emailList, updateEmailList] = useState([]);
	const [noteList, updateNoteList] = useState([]);
	const [emailValue, updateEmailValue] = useState("");
	const [noteValue, updateNoteValue] = useState("");
	const user = useContext(UserContext);
	let username = user.user.name;
	// Schema for contact form
	const schema = yup.object().shape({
		note: yup.string().required("Enter a note first."),
	});

	// Form handling
	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(schema),
	});
	const handleInputChange = (e) => {
		updateNoteValue(e);
	};
	//This function occurs when you click on the update button after editing a note. This will post the filteredNote to the backend.
	const editNote = async (
		i,
		notes,
		oldNote,
		inputNote,
		oldNoteObject,
		database_id
	) => {
		let updatedNote = {
			date: new Date().toLocaleString(),
			email: oldNoteObject.email,
			note: inputNote,
			user: oldNoteObject.user,
		};
		updatedNote.id = database_id;
		//remove oldNote
		const filteredNotes = notes.filter((note) => note.note !== oldNote);
		//add newNote
		let updatedNotes = [...filteredNotes, updatedNote];
		//stringifyNote to send to the backend php
		let stringifyNotes = JSON.stringify(updatedNotes);
		//send objectNote to backend php
		let objectNote = {
			id: database_id,
			notes: stringifyNotes,
		};
		const { success, error } = await updateNote(objectNote);
		if (success) {
			toast.success("Note updated successfully");
			//updates the frontend with updatedNotes
			buildNoteValues(updatedNotes);
			updateExistingNoteValues(
				updatedNotes?.map((v, i) => (
					<div key={i}>
						{i > 0 && <hr />}
						<div
							className='note-header'
							onClick={() => handleEmailList(v.email)}
						>
							<p>
								<span>User:</span> {v.email ? <b>{v.user}</b> : v.user}
							</p>
							<p>
								<span>Date:</span> {v.date}
							</p>
						</div>
						<span className='purple'>Note:</span>
						{id === i ? (
							<></>
						) : (
							<span>{v.note}</span> // Renders a paragraph element for other cases
						)}
						<div className='edit-container'>
							<Button
								className='btn edit'
								title='Grid display'
								onClick={() =>
									handleEdit(i, updatedNotes, noteValue, database_id)
								}
							>
								<i className='fas fa-edit'></i>
							</Button>
							<Button
								className='btn edit'
								title='Row display'
								onClick={() => handleDelete(i, notes, id)}
							>
								<i className='fas fa-trash'></i>
							</Button>
						</div>
					</div>
				))
			);
		}
		error && toast.error(error.message);
	};
	//The action occurs when the edit icon is clicked. It will display the text input and update and cancel buttons.
	const handleEdit = (id, notes, noteValues, database_id) => {
		console.log(database_id, "databaseid");
		updateExistingNoteValues(
			notes?.map((v, i) => (
				<div key={i}>
					{i > 0 && <hr />}
					<div className='note-header' onClick={() => handleEmailList(v.email)}>
						<p>
							<span>User:</span> {v.email ? <b>{v.user}</b> : v.user}
						</p>
						<p>
							<span>Date:</span> {v.date}
						</p>
					</div>
					<p>
						<span>Note:</span>
					</p>
					{/*if the edit button was clicked then display text input, updates and cancel. Else, get rid of buttons */}
					{id === i ? (
						<>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									let inputNote = e.target.elements.note.value;
									editNote(i, notes, v.note, inputNote, v, database_id);
								}}
							>
								<Input
									type='text'
									name='note'
									placeholder={v.note}
									className='form-control'
									register={register}
									onChange={(e) => handleInputChange(e)}
								></Input>{" "}
								<Button className='btn edit' title='Grid display'>
									Update
								</Button>
								<Button
									className='btn edit'
									title='Row display'
									onClick={() => buildNoteValues(notes, noteValue)}
								>
									Cancel
								</Button>
							</form>
						</>
					) : (
						<>
							<p>{v.note}</p>
						</>
					)}
				</div>
			))
		);
	};

	const handleDelete = (id, notes, database_id) => {
		const confirmed = window.confirm(
			"Are you sure you want to delete this note?"
		);
		if (confirmed) {
			//this creates a new array so the original array does not get mutated
			const updatedArray = [...notes];
			updatedArray.splice(id, 1);
			let data = {
				database_id: database_id,
				updatedArray: JSON.stringify(updatedArray),
			};

			if (updatedArray.length === 0) {
				buildNoteValues([]);
				data.updatedArray = "";
				deleteNote(data);
				toast.success("Note deleted successfully");
			} else {
				buildNoteValues(updatedArray);
				deleteNote(data);
				toast.success("Note deleted successfully");
			}
		}
	};

	//# Build Email List
	const buildEmailList = (list) => {
		if (list.length > 1) {
			let emails = "";
			list.forEach((v, i) => {
				emails += list.length - 1 !== i ? v + ", " : v;
			});
			return emailValue !== "" ? emailValue + ", " + emails : emails;
		} else return emailValue !== "" ? emailValue + ", " + list[0] : list[0];
	};
	//this is what is displayed on the screen for notes
	const buildNoteValues = useCallback(
		(existingNote, noteValue) => {
			updateExistingNoteValues(
				existingNote?.map((v, i) => (
					<div key={i}>
						{i > 0 && <hr />}
						<div
							className='note-header'
							onClick={() => handleEmailList(v.email)}
						>
							<p>
								<span>User:</span> {v.email ? <b>{v.user}</b> : v.user}
							</p>
							<p>
								<span>Date:</span> {v.date}
							</p>
						</div>
						<span className='purple'>Note: </span>
						{id === i ? <></> : <span>{v.note}</span>}
						<div className='edit-container'>
							{v.email || v.user === username ? (
								<>
									<Button
										className='btn edit'
										title='Grid display'
										onClick={() => handleEdit(i, existingNote, noteValue, id)}
									>
										<i className='fas fa-edit'></i>
									</Button>
									<Button
										className='btn edit'
										title='Row display'
										onClick={() => handleDelete(i, existingNote, id)}
									>
										<i className='fas fa-trash'></i>
									</Button>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				))
			);
		},
		[updateExistingNoteValues, existingNote]
	);

	//* Handle Email List
	const handleEmailList = (email) => {
		let list = emailList;
		list.push(email);
		updateEmailList(list);
		updateEmailValue(buildEmailList(list));
		toggleShowEmail(true);
	};

	useEffect(() => {
		if (existingNote) {
			buildNoteValues(JSON.parse(existingNote), noteValue);
			toggleShowNote(true);
		}
	}, [existingNote, buildNoteValues]);

	const cancelEmail = (e) => {
		e.preventDefault();
		toggleShowEmail(false);
	};

	const share = (e) => {
		e.preventDefault();
		toggleShowEmail(true);
	};

	//*-----------------------------------
	//# Form submits
	//*-----------------------------------
	//this is to submit a new note
	const onSubmit = async (values) => {
		values.id = id;
		setLoading(true);
		const { success, error } = await postNote(values);
		if (success) {
			toast.success(success.message);
			session.remove("comments");
			const note = { note: values.note, date: "Just now", user: username };
			// If notes already exist
			if (noteList.length > 0) {
				let newNote = [...noteList, note];
				// Add new note
				updateNoteList(newNote);
				buildNoteValues(newNote);
				// If single existing note
			} else if (existingNote) {
				//this converts String Array of objects to an object so that a newNote can be added
				let parseExistingNote = JSON.parse(existingNote);
				let newNote = [...parseExistingNote, note];
				updateNoteList(newNote);
				buildNoteValues(newNote);
				// No existing notes
			} else {
				updateNoteList([note]);
				buildNoteValues([note]);
				toggleShowNote(true);
			}
		}
		error && toast.error(error.message);
		setLoading(false);
	};
	const sendEmail = async (e) => {
		e.preventDefault();
		setLoading(true);

		const { success, error } = await emailNote({ id: link, email: emailValue });
		if (success) {
			toast.success(success.message);
		}
		error && toast.error(error.message);
		setLoading(false);
	};

	return (
		<>
			<>
				{loading ? (
					<Loading />
				) : (
					<>
          {/* displays notes on screen */}
						{showNote && existingNoteValues.length > 0 && (
							<div className='note-container'>{existingNoteValues}</div>
						)}
						<form onSubmit={handleSubmit(onSubmit)}>
							<Textarea
								name='note'
								className='form-control'
								placeholder='Add a note...'
								fullWidth={true}
								register={register}
								error={errors.note?.message}
							/>
							{showEmail && (
								<>
									<Input
										type='text'
										value={emailValue}
										onChange={(e) => updateEmailValue(e)}
										name='email'
										placeholder='Send an email to'
										fullWidth={true}
										className='form-control'
										register={register}
										small='You can add multiple emails by separating then with a comma.'
										error={errors.email?.message}
									/>
								</>
							)}
							{loading ? (
								<Loading />
							) : !showEmail ? (
								<>
									<Button className='btn btn-primary reviewed' text='Save' />
									<Button
										className='btn btn-secondary share'
										onClick={(e) => share(e)}
										text='Share'
									/>
								</>
							) : (
								<>
									<Button
										className='btn btn-success share'
										onClick={(e) => sendEmail(e)}
										text='Send'
									/>
									<Button
										className='btn btn-warning share'
										onClick={(e) => cancelEmail(e)}
										text='Cancel'
									/>
								</>
							)}
						</form>
					</>
				)}
			</>
		</>
	);
};

export default Notes;
